@import "../variables";
@import "../mixins";

.promo-catalog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../assets/promo-catalog-bg.jpg") no-repeat top center;
  background-size: cover;
  margin-top: 180px;

  .info {
    max-width: 800px;
    margin-top: 80px;
    text-align: center;

    @media (min-width: $screen-lg) {
      margin-top: 120px;
    }

    .logo .img {
      height: 40px;

      @media (min-width: $screen-md) {
        height: 56px;
      }

      @media (min-width: $screen-lg) {
        height: 64px;
      }
    }

    .description {
      @include content-font-size;
      margin-top: 16px;
    }
  }

  .catalog-items {
    @include main-container;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 40px;

    @media (min-width: $screen-sm) {
      margin-top: 80px;
      gap: 20px;
    }

    @media (min-width: $screen-lg) {
      flex-wrap: nowrap;
    }

    .item {
      width: 100%;

      @media (min-width: $screen-sm) {
        width: 48%;
      }

      @media (min-width: $screen-lg) {
        width: 25%;
      }

      img {
        width: 100%;
      }
    }
  }
}
