@import "src/scss/variables";
@import "src/scss/mixins";

a.btn, .btn.fluid {
  padding: 7.5px 38px;
  margin-left: 40px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.16px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
}

.btn {
  @include button-base;

  &.primary {
    @include button-primary;
  }

  //&.secondary {
  //  @include button-secondary;
  //}

  &.ghost {
    @include button-ghost;
  }

  //&.shuffle {
  //  @include button-shuffle;
  //}
  //
  //&.follow {
  //  @include button-follow;
  //}

  &.common {
    width: 360px;
    height: 51px;
  }
}
