@import "../variables";
@import "../mixins";

.promo-banner-container {
  @include main-container;
  @include block-top-margin;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 246px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;

  @media (min-width: $screen-sm) {
    padding-bottom: 400px;
  }

  @media (min-width: $screen-md) {
    justify-content: flex-start;
    height: 344px;
    padding: 0 40px;
    text-align: left;
  }

  @media (min-width: $screen-lg) {
    padding: 0 $container-padding-lg;
    height: 456px;
  }

  @media (min-width: $screen-xlg) {
    height: 640px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 352px;
    height: 190px;
    background: url("./../../assets/banner-bg.png") no-repeat center;
    background-size: contain;

    @media (min-width: $screen-sm) {
      width: 635px;
      height: 344px;
    }

    @media (min-width: $screen-md) {
      right: -40px;
      top: 0;
      bottom: auto;
    }

    @media (min-width: $screen-lg) {
      right: -48px;
      width: 841px;
      height: 456px;
    }

    @media (min-width: $screen-xlg) {
      right: -65px;
      width: 1178px;
      height: 640px;
    }
  }

  .content {
    @include content-block;

    .sub-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0.4px;
      color: $footer-text-color;

      @media (min-width: $screen-md) {
        font-size: 18px;
      }
    }

    .title {
      @include title-font;
      margin-top: 16px;
    }

    .description {
      @include content-font-size;
      margin-top: 16px;
    }

    .stores {
      justify-content: center;

      @media (min-width: $screen-md) {
        justify-content: flex-start;
      }
    }
  }
}
