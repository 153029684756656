@import "../variables";
@import "../mixins";


.header {
  @include header-background;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 820px;
    height: $header-height-mobile;
    top: 0;
    right: 0;
    background: url(../../assets/header-circle.svg) no-repeat top right;

    @media (min-width: $new-screen-sm) {
      height: $header-height;
    }
  }

  &.no-circle {
    &:after {
      display: none;
    }
  }

  .main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-height-mobile;

    @media (min-width: $new-screen-sm) {
      height: $header-height;
    }
  }

  .menu {
    display: none;
    margin: 0 auto;

    @media (min-width: $new-screen-md) {
      display: flex;
    }

    &:hover, &:focus, &.has-active {
      .link {
        color: #8A8989;

        &:hover, &:focus, &.is-active {
          color: $light-color;
        }
      }
    }

    .link {
      @include base-animation;
      @include link-separator;
      display: flex;
      align-items: center;
      margin: 0 40px;
      color: $header-link-color;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      letter-spacing: 0;
      text-transform: uppercase;
      text-decoration: none;

      &:before {
        left: -43px;
      }
    }
  }

  .main-menu-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20px;
    height: 20px;
    border: 0;
    background: transparent;
    cursor: pointer;
    margin-top: 2px;
    padding: 0;

    @media (min-width: $new-screen-sm) {
      width: 24px;
      height: 24px;
      margin-top: 5px;
    }

    @media (min-width: $new-screen-lg) {
      display: none;
    }

    &:hover, &.active {
      span {
        &:nth-child(even) {
          width: 60%;
        }
      }
    }

    span {
      @include base-animation;
      display: block;
      width: 100%;
      height: 4px;
      background-color: $light-color;
      border-radius: 2px;

      @media (max-width: $screen-smaller-min) {
        height: 3px;
      }
    }
  }

  .register-login {
    width: 224px;
    height: 40px;

    @media (max-width: $screen-smaller-min) {
      font-size: 0;
      border: none;
      border-radius: 0;
      mask: url(../../assets/sign-in.svg) no-repeat 100% 100%;
      mask-size: contain;
      background: $light-color;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
    }
  }

  .register-block {
    position: relative;

    .btn.ghost {
      background: $light-color;

      @media (min-width: $new-screen-sm) {
        background: transparent;

        &:hover {
          background: $main-color;
        }
      }
    }
  }
}

.brandon-logo-container {
  .link {
    display: block;
    line-height: 0;
  }

  .img {
    height: 24px;
  }
}
