@import "../variables";
@import "../mixins";

.last-call-container {
  @include block-top-margin;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    text-align: center;
  }

  .form-container {
    margin-top: 56px;
  }
}
