@import "../variables";
@import "../mixins";

.contacts-page {
  display: flex;
  flex-direction: column;
  
  &:before {
    content: '';
    position: fixed;
    z-index: -1;
    width: 820px;
    height: 512px;
    top: 47px;
    right: 0;
    background: url(../../assets/header-circle.svg) no-repeat top right;

    @media (min-width: $new-screen-sm) {
      top: 70px;
    }
  }

  &:after {
    content: '';
    position: fixed;
    z-index: -1;
    width: 810px;
    height: 1050px;
    top: 110px;
    left: -175px;
    background: url(../../assets/body-circle.svg) no-repeat top right;

    @media (max-width: $new-screen-sm) {
      background: none;
    }
  }

  .page-content {
    row-gap: 70px;
  }
  
  .contacts-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1055px;
    row-gap: 25px;

    @media (max-width: $new-screen-sm) {
      row-gap: 20px;
    }
  }
  
  .contact-block {
    display: flex;
    justify-content: space-between;

    @media (max-width: $new-screen-sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      @include title-font;
      font-size: 24px;
      font-weight: 800;
      line-height: 40px;
      letter-spacing: -1.5px;
    }

    .email {
      font-size: 20px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0.5px;
      color: $pure-white;
      text-decoration: none;

      @media (max-width: $new-screen-sm) {
        font-size: 18px;
      }
    }
  }

  .contacts-btn {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 54px;
    padding: 0 40px;
    border-radius: 1000px;
    border-width: 0;
    background: linear-gradient(94.59deg, #FF5F61 41.96%, $main-color 58.95%);
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.5px;
    text-decoration: none;
    color: $pure-white;

    @media (max-width: $new-screen-sm) {
      font-size: 14px;
    }
  }

  .separator {
    height: 1px;
    background: $pure-white;
  }
}

