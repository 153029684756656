@import "../variables";
@import "../mixins";

.presale-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 623px;
  padding: 80px 0;
  box-sizing: border-box;
  background: url("./../../assets/presale-bg.jpg") no-repeat center;
  background-size: cover;
  overflow: hidden;

  &:before, &:after {
    display: none;
    content: '';
    width: 554px;
    height: 665px;
    position: absolute;
    bottom: 0;
    z-index: 0;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    @media (min-width: $new-screen-md) {
      display: block;
    }
  }

  &:before {
    left: -200px;
    background-image: url("./../../assets/presale-left.png");

    @media (min-width: $new-screen-lg) {
      left: -100px;
    }

    @media (min-width: $new-screen-xlg) {
      left: 0;
    }
  }

  &:after {
    width: 740px;
    right: -200px;
    background-image: url("./../../assets/dianna-abby.png");

    @media (min-width: $new-screen-lg) {
      right: -100px;
    }

    @media (min-width: $new-screen-xlg) {
      right: 0;
    }
  }

  .bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #11141A 2.9%, rgba(17, 20, 26, 0) 19.9%, rgba(17, 20, 26, 0) 45.89%, #11141A 100%);
  }

  .info {
    position: relative;
    z-index: 2;

    @media (min-width: $new-screen-sm) {
      width: 650px;
    }

    @media (min-width: $new-screen-lg) {
      width: 800px;
    }
  }

  .title, .description {
    text-align: center;
  }

  .description {
    margin-top: 16px;
    text-wrap: balance;
  }

  .subscriptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: $base-top-margin;
  }

  .subscription-item {
    position: relative;
    z-index: 1;
    width: 420px;
    margin: $base-top-margin auto 0;

    @media (max-width: $new-screen-sm) {
      width: 95%;
    }

    &.yearly {
      .cover {
        background: url("https://storage.googleapis.com/brandon-app-prod/media/year.offer_cover.jpeg") no-repeat center;
        background-size: cover;
      }
    }

    &.monthly {
      .cover {
        background: url("https://storage.googleapis.com/brandon-app-prod/media/month.offer_cover.jpeg") no-repeat center;
        background-size: cover;
      }
    }

    .cover {
      position: relative;
      width: 100%;
      height: 200px;
      border-radius: 8px;

      .price, .old-price {
        position: absolute;
        top: 20px;
        right: -6px;
        padding: 4px 12px;
        background: $main-color;
        border-radius: 6px 6px 0 12px;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: $pure-white;
        text-transform: uppercase;

        @media (max-width: $screen-sm-min) {
          @include base-link-typography;
          font-weight: 700;
        }

        &:before {
          position: absolute;
          bottom: -7px;
          right: 0;
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 6px 0 0;
          border-color: rgba(242, 68, 70, 0.5) transparent transparent transparent;
        }
      }

      .old-price {
        top: 58px;
        background: $light-color;
        color: #11141A;

        &:before {
          border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
        }
      }
    }

    .headline {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .title {
        margin-top: 0;
        font-size: 16px;
        font-weight: 800;
        line-height: 24px;
        letter-spacing: -0.6px;
      }
    }

    .renew-info {
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: $footer-text-color;
    }

    .description {
      padding: 8px 16px;
      box-sizing: border-box;
      background-color: #171B22;
      border-radius: 8px;
      font-size: 10px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.3px;
      color: $footer-text-color;
      text-wrap: balance;
      text-align: left;
    }

    .btn {
      display: block;
      width: 420px;
      height: 43px;
      margin-top: 32px;
      margin-left: 0;
      //padding: 16px 0;
      box-sizing: border-box;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      text-transform: none;
    }
  }
}
