@import "../variables";
//@import "../mixins";

@mixin button-colors($color1, $color2) {
  background-color: $color1;
  border-color: $color2;
}

@mixin button-primary-colors {
  @include button-colors($main-color, $main-color);
}

@mixin button-primary-hover-colors {
  @include button-colors(transparent, $light-color);
}

//@mixin button-secondary-colors {
//  @include button-colors($secondary-text-color, $secondary-text-color);
//}
//
//@mixin button-secondary-hover-colors {
//  @include button-colors($background-secondary-color, $background-secondary-color);
//}

@mixin button-ghost-colors {
  @include button-colors(transparent, $light-color);
}

@mixin button-ghost-hover-colors {
  @include button-colors($main-color, $main-color);
}

@mixin button-shuffle-colors {
  @include button-colors($title-color, $title-color);
}

@mixin button-shuffle-hover-colors {
  @include button-colors(rgba(238, 233, 228, 0.2), rgba(238, 233, 228, 0));
}

//@mixin button-disabled-colors {
//  color: $disabled-text-color;
//  background-color: $secondary-text-color;
//  border-color: $secondary-text-color;
//}

@mixin remove-outline {
  &:active, &:focus {
    outline: none;
  }
}

@mixin base-button-radius {
  @include remove-outline;
  border-radius: $input-border-radius;
}

//@mixin button-disabled-state {
//  &:disabled {
//    @include button-disabled-colors;
//    pointer-events: none;
//    cursor: not-allowed;
//  }
//}

@mixin button-base {
  @include base-button-radius;
  @include base-animation;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: $light-color;
  border: transparent solid 2px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }

  &.small {
    border-width: 1px;
  }
}

@mixin button-primary {
  @include button-primary-colors;
  //@include button-disabled-state;

  &:hover, &:focus, &:active {
    @include button-primary-hover-colors;
  }
}

//@mixin button-secondary {
//  @include button-secondary-colors;
//  @include button-disabled-state;
//
//  &:hover, &:focus, &:active {
//    @include button-secondary-hover-colors;
//  }
//}

@mixin button-ghost {
  @include button-ghost-colors;
  //@include button-disabled-state;

  &:visited {
    @include button-ghost-colors;
  }

  &:hover, &:focus, &:active {
    @include button-ghost-hover-colors;
  }
}

//@mixin button-shuffle {
//  @include button-shuffle-colors;
//
//  &:hover, &:focus, &:active {
//    @include button-shuffle-hover-colors;
//  }
//}

//@mixin button-follow {
//  @include button-secondary-colors;
//
//  &.is-followed {
//    @include button-colors(#171B22, #171B22);
//    color: $disabled-text-color;
//  }
//
//  &:hover, &:active,
//  &.is-followed:hover, &.is-followed:active {
//    @include button-primary-hover-colors;
//  }
//
//  &.is-followed:hover, &.is-followed:active {
//    color: $light-color;
//  }
//}
