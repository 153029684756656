/* Colors */
$title-color: #bd8c43;
$main-color: #F24446;
$emphase-color: #011e9e;
$form-placeholder-color: #6A767E;
$black-color: #333132;
$background-color: #141313;
$info-text-color: #9E9E9E;
$error-color: #D34646;
$pure-white: #ffffff;
$light-color: $pure-white;
$gray-color: #3e3f43;
$success-color: #91FF6B;
$header-link-color: #696F7A;

$background-light-color: #FBFBFB;

$form-bg-color: #202734;


$border-hover-color: #A7ACCF;
$auth-link-color: #84AEFF;
$input-bg-color: #24272C;
$input-hover-bg-color: #2F3339;

$mobile-input-color: #8A8989;
$light-theme-mobile-input-color: #999B9F;
$footer-text-color: $light-theme-mobile-input-color;

/* Sizes */
$header-height: 88px;
$header-height-mobile: 56px;
$base-margin-old: 15px;
$base-margin: 40px;
$base-margin-md: 48px;
$base-margin-mobile: 16px;
$base-top-margin: 56px;
$base-margin-lg: $base-top-margin;
$video-border-radius: 10px;
$card-border-radius: 8px;
$base-margin-sm: 16px;
$base-margin-sm-half: calc(#{$base-margin-sm} / 2);

$base-padding: 20px;
$base-padding-md: 25px;
$base-padding-lg: 40px;
$container-padding-lg: 80px;

$input-border-radius: 10px;
$submit-border-radius-xs: 30px;
$submit-border-radius-md: 40px;

$catalog-header-height: $header-height;

$scale-size: 0.95;

$animation-speed: 0.2s;

$mobile-input-color: #8A8989;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

$screen-xxs: 400px !default;
$screen-xxs-min: $screen-xxs !default;

// Extra small screen / phone
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;

$screen-smaller: 701px !default;
$screen-smaller-min: ($screen-smaller - 1) !default;

// Small screen / tablet
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;

// Medium screen / desktop
$screen-md: 1300px !default;
$screen-md-min: $screen-md !default;

// Default screen / laptop
$screen-lmd: 1366px !default;
$screen-lmd-min: $screen-lmd !default;

// Large screen / wide desktop
$screen-lg: 1600px !default;
$screen-lg-min: $screen-lg !default;

$screen-xlg: 1920px;


// Extra large
$screen-xlg-min: 1450px !default;
$screen-xlg-max: 1449px !default;

// QHD
$screen-qhd-min: 1921px !default;

// So media queries don't overlap when required, provide a maximum
//$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-xs-max: 481px !default;
$screen-sm-max: ($screen-sm-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$screen-lg-max: ($screen-xlg-min - 1) !default;

$color-white: #FBFBFB;
$color-black: #333132;
$color-blue: #0057FD;

$new-screen-sm: 768px;
$new-screen-md: 1200px;
$new-screen-lg: 1600px;
$new-screen-xlg: 1920px;
