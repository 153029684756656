@import "../variables";
@import "../mixins";

.title {
  @include title-font;
  font-size: 32px;
  line-height: 48px;
  font-weight: 900;
  letter-spacing: 1.4px;
  margin-top: 20px;
  text-transform: uppercase;

  @media (min-width: $screen-md) {
    font-size: 36px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0.5px;
  }
}

.emphase {
  background: $main-color;
  border-radius: 8px;
  padding: 0 6px;
}
