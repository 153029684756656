@import "../variables";
@import "../mixins";

.form-container {
  .form {
    display: flex;
    align-items: center;
    width: 320px;
    height: 49px;
    background-color: $form-bg-color;
    padding: 8px;
    box-sizing: border-box;
    letter-spacing: 0;
    text-align: center;
    border-radius: 8px;
    border: 1px solid $pure-white;

    @media (min-width: $screen-md) {
      width: 478px;
      height: 61px;
      padding: 12px;
    }

    &.success {
      border-color: $success-color;

      .btn {
        background-color: $success-color;
      }
    }

    .email {
      width: 100%;
      color: $light-color;
      background-color: transparent;
      border: none;
      outline: none;
      margin-left: 8px;
      font-size: 10px;
      line-height: 15px;

      @media (min-width: $screen-md) {
        margin-left: 12px;
        font-size: 12px;
        line-height: 18px;
      }
    }

    .btn {
      margin-left: 12px;
      padding: 8px 12px;
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 12px;
      line-height: 150%;
      font-weight: 600;
      background-color: #F24446;
      color: $pure-white;
      border: none;
      white-space: nowrap;

      @media (min-width: $screen-md) {
        margin-left: 20px;
        padding: 8px 20px;
        font-size: 14px;
      }
    }
  }

  .success-block {
    @include form-font-size;
    display: flex;
    margin-top: 16px;
    color: $success-color;
    letter-spacing: 0;
    text-align: center;

    @media (min-width: $screen-md) {
      margin-top: 32px;
    }

    .icon-success {
      display: block;
      width: 19px;
      height: 19px;
      background: url("./../../assets/icon-success.svg") no-repeat center;
      background-size: contain;
      margin-right: 16px;

      @media (min-width: $screen-md) {
        width: 28px;
        height: 28px;
      }
    }
  }
}
