@import "../variables";
@import "../mixins";


.stores {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 32px;

  .icon {
    @include base-animation;
    width: 120px;
    height: 40px;
    font-size: 0;

    &:hover {
      opacity: 0.5;
    }
  }
}
