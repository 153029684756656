@import "scss/variables";
@import "scss/reset";
@import "scss/mixins";

@import "scss/layout/main_container";
@import "scss/layout/page_content";

@import "scss/page/terms";
@import "scss/page/help_center";
@import "scss/page/contacts";

@import "scss/components/common";
@import "scss/components/main";
@import "scss/components/form";
@import "scss/components/promo-catalog";
@import "scss/components/promo_banner";
@import "scss/components/last-call";
@import "scss/components/footer";
@import "scss/components/header";
@import "scss/components/buttons";
@import "scss/components/presale";
@import "scss/components/stores";
@import "scss/components/icons";

:root {
  --app-screen-height: 100vh;
}

body {
  background: $background-color;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: $light-color;
}
