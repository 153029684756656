@import "../variables";
@import "../mixins";

.help-center-page {
  display: flex;
  flex-direction: column;

  .header {
    &:after {
      background: none;
    }
  }

  .help-center-section {
    display: flex;
    flex-direction: column;
    max-width: 1168px;
    row-gap: 20px;
  }
  
  .faq-container {
    &.open {
      .faq-title {
        border-radius: 30px 30px 0 0;
        padding: 24px 40px;
        background: $main-color;
        transition: border-radius 0.1s linear,
                    background 0.1s ease-out,
                    padding 0.1s ease-out;

        @media (max-width: $new-screen-sm) {
          padding: 16px 20px;
          font-size: 10px;
        }

        &::before, &::after {
          transform: rotate(180deg);
        }
      }

      .faq-content {
        box-sizing: border-box;
        border-radius: 0 0 30px 30px;
        padding: 24px 40px;
        background: #171B22;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        max-height: none;
        opacity: 1;
        transition: opacity 0.4s ease-in-out, 
                    max-height 0.5s ease-in-out;
  
        @media (max-width: $new-screen-sm) {
          padding: 16px 20px;
          font-size: 10px;
        }
      }
    }

    .faq-title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      border-radius: 1000px;
      padding: 14.5px 40px;
      background: #171B22;
      cursor: pointer;
      font-size: 20px;
      // text-transform: none;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0.5px;
      transition: border-radius 0.1s linear,
                  background 0.1s ease-out,
                  padding 0.1s ease-out;

      @media (max-width: $new-screen-sm) {
        padding: 10px 50px 10px 20px;
        font-size: 10px;
      }

      &::before, &::after {
        @include base-animation;
        position: absolute;
        content: "";
        border-radius: 1000px;
        height: 3px;
        width: 30px;
        right: 40px;
        background-color: $pure-white;

        @media (max-width: $new-screen-sm) {
          height: 2px;
          width: 25px;
          right: 25px;
        }
      }
      
      &::after {
        transform: rotate(90deg);
      }
    }

    .faq-content {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: opacity 0.2s ease-in-out, 
                  max-height 0.2s ease-in-out;
    }
  }

  .colored-link {
    color: $main-color;
  }
}
