@import "../variables";
@import "../mixins";

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  padding-top: $header-height-mobile + $base-padding;

  @media (min-width: $new-screen-sm) {
    padding-top: $header-height + $base-top-margin;
  }

  .title {
    margin-top: 0;
    text-align: center;
    font-size: 36px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0.5px;
  }
}