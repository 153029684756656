@import "variables";
@import "mixins/buttons";

@mixin button-colors($color1, $color2) {
  background-color: $color1;
  border-color: $color2;
}

@mixin button-primary-colors {
  @include button-colors($main-color, $main-color);
}

@mixin button-primary-hover-colors {
  @include button-colors(transparent, $light-color);
}

@mixin main-container {
  padding: 0 24px;
  box-sizing: border-box;

  @media (min-width: $screen-sm) {
    padding: 0 40px;
  }

  @media (min-width: $screen-lg) {
    padding: 0 $container-padding-lg;
  }
}

@mixin title-font {
  font-family: 'Barlow', sans-serif;
}

@mixin block-top-margin {
  margin-top: 160px;

  @media (min-width: $screen-md) {
    margin-top: 200px;
  }
}

@mixin top-margin {
  margin-top: 56px;

  @media (min-width: $screen-md) {
    margin-top: 40px;
  }
}

@mixin content-block {
  @media (min-width: $screen-md) {
    border-left: 1px solid $pure-white;
    padding: $base-padding-md 0 $base-padding-md $base-padding-md;
  }

  @media (min-width: $screen-xlg) {
    padding: $base-padding-lg 0 $base-padding-lg $base-padding-lg;
  }
}

@mixin content-font-size {
  font-size: 14px;
  line-height: 22px;


  @media (min-width: $screen-md) {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.4px;
  }
}

@mixin form-font-size {
  font-size: 12px;
  line-height: 18px;

  @media (min-width: $screen-md) {
    font-size: 14px;
    line-height: 21px;
  }
}

@mixin header-background {
  background: rgba(17, 20, 26, 0.7);
  mix-blend-mode: normal;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}

@mixin link-separator {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -13px;
    height: 100%;
    width: 2px;
    background-color: $header-link-color;
  }

  &:first-child:before {
    background-color: transparent;
  }
}

@mixin base-animation() {
  transition: all $animation-speed;
}

@mixin base-link-typography {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
