

.icon-store-apple {
  background: url("../../assets/icon-store-apple.svg") no-repeat center;
  background-size: contain;
}

.icon-store-google {
  background: url("../../assets/icon-store-google.svg") no-repeat center;
  background-size: contain;
}

.icon-store-roku {
  background: url("../../assets/icon-store-roku.svg") no-repeat center;
  background-size: contain;
}
