@import "../variables";
@import "../mixins";

.main {
  @include main-container;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--app-screen-height);

  @media (min-width: $screen-md) {
    justify-content: flex-start;
  }

  .videoBG {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(17, 20, 26, 0.00) 0%, #11141A 100%);
    }

    .videoElement {
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .content {
    @include content-block;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $screen-md) {
      align-items: flex-start;
    }

    .logo {
      width: 320px;

      @media (min-width: $screen-xlg) {
        width: 400px;
      }

      .img {
        width: 100%;
      }
    }

    .description {
      @include top-margin;
      @include content-font-size;
      width: 320px;
      text-align: center;
      text-wrap: balance;

      br {
        display: none;
      }

      @media (min-width: $screen-md) {
        width: 680px;
        text-align: left;

        br {
          display: block;
        }
      }

      @media (min-width: $screen-xlg) {
        width: 840px;
      }
    }

    .btn {
      display: flex;
      align-items: center;
      width: 360px;
      height: 48px;
      margin-top: $base-top-margin;
      margin-left: 0;
      background-color: $main-color;
      border-radius: 8px;
      letter-spacing: 0;
    }

    .form-title {
      @include top-margin;
      @include content-font-size;
      font-weight: 700;
      text-align: center;

      @media (min-width: $screen-md) {
        text-align: left;
      }
    }

    .form-container {
      margin-top: 20px;
    }

    .availability {
      margin-top: $base-top-margin;
    }

    .available-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0;
      text-align: center;

      @media (min-width: $screen-md) {
        text-align: left;
      }
    }

    .platforms {
      display: flex;
      justify-content: center;
      column-gap: 32px;
      margin-top: 20px;

      @media (min-width: $screen-sm) {
        column-gap: $base-top-margin;
      }

      @media (min-width: $screen-md) {
        justify-content: flex-start;
      }

      .platform {
        height: 12px;

        @media (min-width: $screen-sm) {
          height: 24px;
        }
      }

      .web {
        width: 50px;
        background: url("../../assets/icon-platform-web.svg") no-repeat center;
        background-size: contain;

        @media (min-width: $screen-sm) {
          width: 101px;
        }
      }

      .apple {
        width: 56px;
        background: url("../../assets/icon-platform-apple.svg") no-repeat center;
        background-size: contain;

        @media (min-width: $screen-sm) {
          width: 113px;
        }
      }

      .android {
        width: 80px;
        background: url("../../assets/icon-google.svg") no-repeat center;
        background-size: contain;

        @media (min-width: $screen-sm) {
          width: 110px;
        }
      }

      .android-tv {
        width: 80px;
        background: url("../../assets/icon-google-tv.svg") no-repeat center;
        background-size: contain;

        @media (min-width: $screen-sm) {
          width: 140px;
        }
      }

      .roku {
        width: 40px;
        background: url("../../assets/icon-platform-roku.svg") no-repeat center;
        background-size: contain;

        @media (min-width: $screen-sm) {
          width: 81px;
        }
      }
    }
  }
}
