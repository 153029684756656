@import "../variables";
@import "../mixins";

footer {
  @include block-top-margin;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 56px;
  color: $footer-text-color;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.3px;
  text-align: center;

  @media (min-width: $screen-md) {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: $screen-xlg) {
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 25px;
  }

  .menu {
    display: flex;
    margin-bottom: 32px;
    column-gap: 32px;

    .link {
      @include base-animation;
      @include link-separator;
      color: $light-color;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.3px;
      text-decoration: none;

      &:visited {
        color: $light-color;
      }

      &:before {
        left: -16px;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .socials {
    display: flex;
    justify-content: center;
    column-gap: 16px;
    margin-top: 16px;

    .link {
      display: block;
      width: 32px;
      height: 32px;
      background-color: $light-color;
      text-indent: -9999px;

      &.youtube {
        mask: url("./../../assets/icon-youtube.svg") no-repeat 100% 100%;
        mask-size: contain;
      }

      &.facebook {
        mask: url("./../../assets/icon-fb.svg") no-repeat 100% 100%;
        mask-size: contain;
      }

      &.instagram {
        mask: url("./../../assets/icon-insta.svg") no-repeat 100% 100%;
        mask-size: contain;
      }

      &.twitter {
        mask: url("./../../assets/icon-x.svg") no-repeat 100% 100%;
        mask-size: contain;
      }

      &.tiktok {
        mask: url("./../../assets/icon-tiktok.svg") no-repeat 100% 100%;
        mask-size: contain;
      }
    }
  }

  .copyrights {
    margin-top: 32px;

    a {
      color: $footer-text-color;

      &:hover, &:active {
        text-decoration: none;
      }
    }
  }
}
