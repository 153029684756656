@import "../variables";
@import "../mixins";

.terms-page {
  position: relative;

  &:before {
    content: '';
    position: fixed;
    z-index: -1;
    width: 820px;
    height: 512px;
    top: 47px;
    right: 0;
    background: url(../../assets/header-circle.svg) no-repeat top right;

    @media (min-width: $new-screen-sm) {
      top: 70px;
    }
  }
}

.terms-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;
  padding-top: calc($header-height-mobile + $base-padding);
  box-sizing: border-box;

  @media (min-width: $new-screen-sm) {
    padding-top: calc($header-height + $container-padding-lg);
  }

  .title {
    text-align: center;
  }

  .sub-header {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.4px;
    text-transform: none;
  }
}

.terms-section {
  max-width: 1020px;

  .title {
    @include title-font;
    margin-top: 0;
    font-size: 36px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0.5px;
    text-align: center;
  }

  .content {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.4px;
    word-break: break-word;
  }

  a {
    color: $light-color;
  }

  b {
    font-size: 20px;
    font-weight: 800;
  }

  ul {
    margin-top: 0;
    padding-left: 16px;
  }
}
