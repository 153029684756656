@import "../variables";
@import "../mixins";

.main-container {
  padding-inline: $base-margin-mobile;
  box-sizing: border-box;
  margin-bottom: auto;

  @media (min-width: $new-screen-sm) {
    padding-inline: $base-margin;
  }

  @media (min-width: $new-screen-lg) {
    padding-inline: $base-top-margin;
  }
}
